import React, { useEffect, useState } from "react";
import { Paper, Box, Typography } from "@mui/material";
import theme from "../theme";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchAboutPage } from "../../api/siteDataApi";

const AboutUs = ({ isDesktop }) => {
  const [aboutData, setaboutData] = useState(null);

  useEffect(() => {
    getSiteLogo();
  }, []);

  const getSiteLogo = async () => {
    try {
      const data = await fetchAboutPage();
      setaboutData(data[0]);
    } catch (error) {
      console.error("Error fetching fetchedSiteLogo data:", error);
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: isDesktop ? theme.dimensions.contentWidth : "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: 400,
          position: "relative",
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: theme.spacing(2),
          marginTop: `calc(${theme.spacing(4)} * 1)`,
        }}
      >
        <img
          src={aboutData?.title_img}
          alt="About Us"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
      </Box>
      <Box
        style={{
          width: "98%",
          padding: theme.spacing(2),
          backgroundColor: theme.colors.secondary,

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <Typography
          color={theme.colors.textSecondary}
          variant="h4"
          component="h2"
        >
          {aboutData?.about_title}
        </Typography>
      </Box>
      <Box
        elevation={3}
        sx={{
          width: "98%",
          padding: theme.spacing(2),
          backgroundColor: theme.colors.halfTransparentSecondary,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <ReactQuill
          style={{ color: theme.colors.textPrimary }}
          value={aboutData?.about_description}
          readOnly
          theme="bubble"
        />
      </Box>
    </Box>
  );
};

export default AboutUs;
