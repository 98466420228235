import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import Logo from "../widgets/Logo";
import LoadingIndicator from "../widgets/LoadingIndicator";
import theme from "../theme";

const NotFound = () => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) =>
        prevCountdown > 0 ? prevCountdown - 1 : 0
      );
    }, 1000);

    const redirectTimeout = setTimeout(() => {
      window.location.replace("/");
    }, 10000);

    return () => {
      clearInterval(interval);
      clearTimeout(redirectTimeout);
    };
  }, []);

  const currentPath = window.location.pathname;

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <Box
        boxShadow={3}
        borderRadius={8}
        p={4}
        bgcolor={theme.colors.secondary}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Logo position={"relative"} alt={"404"} />
        <LoadingIndicator />
        <Typography variant="h4" color="error">
          404 {currentPath} nicht gefunden
        </Typography>
        <Typography variant="body1" mt={2}>
          In {countdown} Sekunden wird zur Hauptseite weitergeleitet.
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 2, backgroundColor: theme.colors.btnCancel }}
          onClick={() => {
            window.location.replace("/");
          }}
        >
          Oder klicke hier
        </Button>
      </Box>
    </Box>
  );
};

export default NotFound;
