import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import theme from "../theme";
export const ImprintContent = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        Impressum
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        <Typography variant="body2">
          <strong>Ihr Unternehmensname</strong>
          <br />
          Straße und Hausnummer
          <br />
          PLZ und Ort
          <br />
          E-Mail: ihre.email@example.com
          <br />
          Telefon: +123 456 789
          <br />
          <br />
          Geschäftsführer: Max Mustermann
          <br />
          Handelsregister: Register-Nummer
          <br />
          USt-IdNr.: DE123456789
          <br />
          <br />
        </Typography>
        <Typography variant="body2">
          Verantwortlich für den Inhalt:
          <br />
          <a
            href="https://solutiondynamic.de/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Solution Dynamic
          </a>
          <br />
          <br />
          Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS)
          für Verbraucher:
          <br />
          <a
            href="https://ec.europa.eu/consumers/odr/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://ec.europa.eu/consumers/odr/
          </a>
          <br />
          <br />
          Wir sind nicht bereit und nicht verpflichtet, an einem
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: theme.colors.secondary,
        }}
      >
        <Button
          onClick={onClose}
          style={{
            backgroundColor: theme.colors.btnCancel,
            color: theme.colors.textSecondary,
          }}
        >
          Zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const PrivacyPolicyContent = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        Datenschutzerklärung
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        <Typography variant="body2">
          <strong>Datenschutzerklärung für die Nutzung dieser Website</strong>
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
          <br />
          <br />
          Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et justo duo dolores et ea
          rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem
          ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
          sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et
          dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam
          et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
          takimata sanctus est Lorem ipsum dolor sit amet.
          <br />
          <br />
        </Typography>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: theme.colors.secondary,
        }}
      >
        <Button
          onClick={onClose}
          style={{
            backgroundColor: theme.colors.btnCancel,
            color: theme.colors.textSecondary,
          }}
        >
          Zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
};
