import React from "react";
import Carousel from "react-material-ui-carousel";
import { Box, Typography } from "@mui/material";
import theme from "../theme";
import OrderProduct from "../widgets/Products/OrderProduct";
import Slider from "../widgets/Slider";
import ReactQuill from "react-quill";

const Home = ({ isDesktop }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        maxWidth: isDesktop ? theme.dimensions.contentWidth : "100%",
      }}
    >
      <Slider isDesktop={isDesktop} />

      <Box
        style={{
          width: "98%",
          padding: theme.spacing(2),
          backgroundColor: theme.colors.secondary,

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          margin: "auto",
          marginTop: "40px",
        }}
      >
        <Typography
          color={theme.colors.textSecondary}
          variant="h4"
          component="h2"
        >
          {"Lorem ipsum dolor sit amet"}
        </Typography>
      </Box>
      <Box
        elevation={3}
        sx={{
          width: "98%",
          padding: theme.spacing(2),
          backgroundColor: theme.colors.halfTransparentSecondary,
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
          margin: "auto",
        }}
      >
        <ReactQuill
          style={{ color: theme.colors.textPrimary }}
          value={
            "consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
          }
          readOnly
          theme="bubble"
        />
      </Box>
    </Box>
  );
};

export default Home;
