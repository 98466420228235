import React from "react";
import { IconButton, Badge } from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddIcon from "@mui/icons-material/Add";
import theme from "../../theme";

const AddToCartIcon = ({ onClick }) => {
  return (
    <IconButton
      onClick={onClick}
      color="inherit"
      style={{ color: theme.colors.accent }}
    >
      <Badge>
        <ShoppingCartIcon />
      </Badge>
      <AddIcon
        style={{ fontSize: 16, position: "absolute", top: 0, right: 0 }}
      />
    </IconButton>
  );
};

export default AddToCartIcon;
