import React from "react";
import { Box, Container, useMediaQuery } from "@mui/material";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import Home from "../pages/Home";
import AboutUs from "../pages/AboutUs";
import NotFound from "../pages/NotFound";
import theme from "../theme";
import Order from "../pages/Order";
const AppShell = () => {
  const isDesktop = useMediaQuery("(min-width:600px)");

  const routes = [
    { path: "/", element: <Home isDesktop={isDesktop} /> },
    { path: "/about", element: <AboutUs isDesktop={isDesktop} /> },
    { path: "/order", element: <Order isDesktop={isDesktop} /> },
    { path: "*", element: <NotFound /> },
  ];

  return (
    <Router>
      <div>
        <Header isDesktop={isDesktop} />
        <Box
          style={{
            display: "flex",
            justifyContent: "center",

            paddingTop: isDesktop
              ? theme.navigation.height
              : `calc(${theme.navigation.height} - 20px)`,
          }}
        >
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Box>

        <Footer isDesktop={isDesktop} />
      </div>
    </Router>
  );
};

export default AppShell;
