import React from "react";
import OrderProduct from "../widgets/Products/OrderProduct";
import { Box } from "@mui/material";

const Order = ({ isDesktop }) => {
  return (
    <Box sx={{ width: "80%" }}>
      <OrderProduct isDesktop={isDesktop} />
    </Box>
  );
};

export default Order;
