import {
  Container,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Box,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  fetchProductGroups,
  fetchProductsByGroup,
} from "../../../api/siteDataApi";
import { API_URL } from "../../../const";
import ProductGroups from "./ProductsGroups";
import CartPopup from "./CartPopup";
import LoadingIndicator from "../LoadingIndicator";
import Loading from "./Loading";

const CartIconWithBadge = ({ cartItems, onClick }) => {
  const totalQuantity = cartItems.reduce(
    (total, item) => total + item.quantity,
    0
  );

  return (
    <IconButton
      color="secondary"
      onClick={onClick}
      disabled={totalQuantity === 0}
    >
      <div style={{ position: "relative" }}>
        <ShoppingCartIcon style={{ width: "35px", height: "35px" }} />
        {totalQuantity > 0 && (
          <div
            style={{
              position: "absolute",
              top: -8,
              right: -8,
              width: 25,
              height: 25,
              fontSize: 21,
              background: theme.colors.accent,
              borderRadius: "50%",
              color: theme.colors.textSecondary,
              padding: "0px",
            }}
          >
            {totalQuantity}
          </div>
        )}
      </div>
    </IconButton>
  );
};
const OrderProduct = ({ isDesktop }) => {
  const [productGroups, setProductGroups] = useState([]);
  const [openGroups, setOpenGroups] = useState({});
  const [products, setProducts] = useState({});
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cart, setCart] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isCartEmpty, setIsCartEmpty] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [orderNote, setOrderNote] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch product groups
        const groupData = await fetchProductGroups();
        setProductGroups(groupData);

        const initialOpenGroupsState = {};
        const initialProductsState = {};

        for (const group of groupData) {
          initialOpenGroupsState[group.id] = true;

          // Await the product data for each group
          const productData = await fetchProductsByGroup(group.id);
          initialProductsState[group.id] = productData;
        }

        setOpenGroups(initialOpenGroupsState);
        setProducts(initialProductsState);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchProducts = async (groupId) => {
    try {
      const data = await fetchProductsByGroup(groupId);
      setProducts((prevProducts) => ({
        ...prevProducts,
        [groupId]: data,
      }));
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const onGroupClick = async (groupId) => {
    // If the products for the group are not fetched yet, fetch them
    if (!products[groupId].length) {
      await fetchProducts(groupId);
    }

    // Toggle the open state for the clicked group
    setOpenGroups((prevOpenGroups) => ({
      ...prevOpenGroups,
      [groupId]: !prevOpenGroups[groupId],
    }));
  };

  const onProductSelection = (groupId, productId, quantity) => {
    // Update the selected products with the chosen quantity
    const updatedProducts = [...selectedProducts];
    const existingProductIndex = updatedProducts.findIndex(
      (p) => p.productId === productId
    );

    if (existingProductIndex !== -1) {
      // Update quantity if the product is already in the list
      updatedProducts[existingProductIndex].quantity = quantity;
    } else {
      // Add the product to the list
      updatedProducts.push({ groupId, productId, quantity });
    }

    setSelectedProducts(updatedProducts);
  };

  const onPlaceOrder = async () => {
    console.log("onPlaceOrder: order_note:" + orderNote);
    try {
      const response = await fetch(API_URL + "/orders", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_id: 999, // UserID Benötigt für bestellung, user muss in Webseite Existieren
          ordered_as_guest: 1,
          first_name: firstName,
          last_name: lastName,
          order_note: orderNote,
          email: email,
          products: cart,
        }),
      });
      if (response.ok) {
        console.log("Order placed successfully!");
        // Clear the cart after placing the order
        setIsPopupOpen(false);
        setCart([]);
        setFirstName("");
        setLastName("");
        setOrderNote("");
        setEmail("");
      } else {
        console.error("Failed to place order:", response.statusText);
      }
    } catch (error) {
      console.error("Error placing order:", error.message);
    }
  };

  const onRemoveItem = (index) => {
    // Create a copy of the cart
    const updatedCart = [...cart];

    // Decrement the quantity by one
    updatedCart[index].quantity -= 1;

    // Remove the product if the quantity becomes zero
    if (updatedCart[index].quantity <= 0) {
      updatedCart.splice(index, 1);
    }

    // Update the cart state
    setCart(updatedCart);
  };

  const onAddToCart = (groupId, productId, productName) => {
    // Check if the product is already in the cart
    const existingCartItem = cart.find(
      (item) => item.groupId === groupId && item.productId === productId
    );

    if (existingCartItem) {
      // If the product is already in the cart, update its quantity
      const updatedCart = cart.map((item) =>
        item === existingCartItem
          ? { ...item, quantity: item.quantity + 1 }
          : item
      );
      setCart(updatedCart);
    } else {
      // If the product is not in the cart, add it with quantity 1
      setCart((prevCart) => [
        ...prevCart,
        { groupId, productId, productName, quantity: 1 }, // Include productName here
      ]);
    }

    console.log(
      `Added product ${productId} (${productName}) to the cart in group ${groupId}`
    );
  };

  const onTogglePopup = () => {
    setIsPopupOpen((prevIsPopupOpen) => !prevIsPopupOpen);
  };
  if (loading) {
    return (
      <Container sx={{ height: "100vh", backgroundColor: "transparent" }}>
        <Loading />
      </Container>
    );
  }
  return (
    <Container
      style={{
        backgroundColor: "transparent",
        maxWidth: "100%",
        height: "100%",
        padding: theme.space.large,
        paddingBottom: theme.footer.height,
        flex: "1",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          alignItems: "center",
          marginTop: theme.space.large,
          color: theme.colors.textSecondary,
          backgroundColor: theme.colors.secondary,
          borderRadius: `${theme.dimensions.borderRadiusContent} ${theme.dimensions.borderRadiusContent} 0 0`,
        }}
      >
        <Typography variant="h2">Bestellen und abholen</Typography>
      </div>
      <ProductGroups
        isDesktop={isDesktop}
        productGroups={productGroups}
        products={products}
        openGroups={openGroups}
        onGroupClick={onGroupClick}
        onAddToCart={onAddToCart}
      />
      <Tooltip
        style={{
          position: "fixed",
          zIndex: 99,
          top: "55px",
          right: "70px",
        }}
        title={
          <Typography variant="subtitle2">
            <b>Warenkorb</b>
          </Typography>
        }
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: theme.colors.accent,
              color: theme.colors.textSecondary,
            },
          },
        }}
      >
        {" "}
        <Box
          sx={{
            position: "fixed",
            zIndex: 99,
            top: "25px",
            right: "45px",
          }}
        >
          <CartIconWithBadge cartItems={cart} onClick={onTogglePopup} />
        </Box>
      </Tooltip>

      {isPopupOpen && (
        <CartPopup
          cart={cart}
          onClose={() => setIsPopupOpen(false)}
          onPlaceOrder={onPlaceOrder}
          onRemoveItem={onRemoveItem}
          lastName={lastName}
          firstName={firstName}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setOrderNote={setOrderNote}
          setEmail={setEmail}
        />
      )}
    </Container>
  );
};

export default OrderProduct;
