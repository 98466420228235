import { fetchData } from "./api";

export const fetchSiteTitle = async () => {
  const data = await fetchData("/settings/");
  console.log("Fetching site title...", data[0].site_title);
  return data ? data[0].site_title || "Default Title" : "Default Title";
};

export const fetchSiteLogo = async () => {
  const data = await fetchData("/settings/");
  console.log("Fetching site logo...", data[0].site_logo);
  return data ? data[0].site_logo || "Default Logo" : "Default Logo";
};

export const fetchProductGroups = async () => {
  try {
    return await fetchData("/product-groups/");
  } catch (error) {
    console.error("Error fetching product groups:", error);
    throw error;
  }
};

export const fetchProductsByGroup = async (groupId) => {
  try {
    return await fetchData(`/product-groups/${groupId}/products`);
  } catch (error) {
    console.error("Error fetching product groups:", error);
    throw error;
  }
};

export const fetchAboutPage = async () => {
  try {
    return await fetchData("/pages/about");
  } catch (error) {
    console.error("Error fetching about page:", error);
    throw error;
  }
};
