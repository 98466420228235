// ProductGroups.js
import React from "react";
import { Box } from "@mui/material";
import Product from "./Product";
import { Link as ScrollLink } from "react-scroll";
import theme from "../../theme";
import ScrollNavLink from "../ScrollNavLink";

const ProductGroups = ({
  productGroups,
  products,
  openGroups,
  onGroupClick,
  onProductSelection,
  onAddToCart,
  isDesktop,
}) => {
  const offsetValue = isDesktop ? -130 : -110;
  return (
    <Box>
      <Box
        textAlign="center"
        sx={{
          backgroundColor: theme.colors.secondary,
        }}
      >
        {productGroups.map(
          (group) =>
            isDesktop && (
              <ScrollNavLink
                key={group.id}
                group={group}
                offsetValue={offsetValue}
              />
            )
        )}
      </Box>
      <Box>
        {" "}
        {productGroups.map((group) => (
          <Product
            key={group.id}
            group={group}
            products={products[group.id]}
            openGroups={openGroups}
            onGroupClick={onGroupClick}
            onProductSelection={onProductSelection}
            onAddToCart={onAddToCart}
            position={group.position}
          />
        ))}
      </Box>
    </Box>
  );
};

export default ProductGroups;
