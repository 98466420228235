import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  MenuList,
  MenuItem,
  ListItemText,
  Box,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../theme";
import { useNavigate } from "react-router-dom";

const Menu = ({ isDesktop, onRouteClick }) => {
  const [fadeIn, setFadeIn] = useState(false);
  const [clickedItem, setClickedItem] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Trigger the fade-in effect after the component has mounted
    setFadeIn(true);
  }, []);

  const menuItems = [
    {
      label: "STARTSEITE",
      route: "/",
    },
    {
      label: "BESTELLEN UND ABHOLEN",
      route: "/order",
    },
    {
      label: "DAS SIND WIR",
      route: "/about",
    },
  ];

  const onMenuItemClick = (route) => {
    console.log(`${route} clicked!`);
    navigate(route);
    setClickedItem(route);
    setTimeout(() => setClickedItem(null), 300);
    onRouteClick(route);
  };

  const containerStyle = {
    position: "relative",
    display: "flex",

    color: theme.colors.textSecondary,
    backgroundColor: theme.colors.secondary,
    opacity: fadeIn ? 1 : 0,

    transition: `opacity ${theme.transitions.onLoad} ease-in-out`,
  };

  const menuListStyle = {
    display: "flex",
    width: "100%",
    flexDirection: !isDesktop ? "column" : "row",
  };
  const menuItemStyle = (route) => ({
    height: theme.navigation.height,

    transition: `color ${theme.transitions.middle} ease-in-out, background-color ${theme.transitions.middle} ease-in-out, transform ${theme.transitions.short} ease-in-out, border-bottom ${theme.transitions.middle} ease-in-out`,
    borderBottom: "2px solid transparent",
    "&:hover": {
      borderBottom: `2px solid ${theme.colors.accent}`,
      color: theme.colors.accent,
      backgroundColor: "transparent",
    },
    transform: clickedItem === route ? "scale(0.9)" : "scale(1)",
  });

  return (
    <Box style={containerStyle} className={fadeIn ? "visible" : ""}>
      <MenuList style={menuListStyle}>
        {menuItems.map((item) => (
          <MenuItem
            key={item.route}
            onClick={() => onMenuItemClick(item.route)}
            sx={menuItemStyle(item.route)}
            disableRipple
          >
            <Link
              to={item.route}
              style={{
                textDecoration: "none",
                color: "inherit",
                display: "block",
              }}
            >
              <ListItemText>{item.label}</ListItemText>
            </Link>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};

export default Menu;
