// ScrollNavLink.js
import React from "react";
import { Link as ScrollLink } from "react-scroll";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import theme from "../theme";

const ScrollNavLink = ({ group, offsetValue, isDesktop }) => (
  <ScrollLink
    to={`group-${group.id}`}
    spy={true}
    smooth={true}
    duration={500}
    offset={offsetValue}
    style={{ marginRight: isDesktop ? "20px" : "10px", cursor: "pointer" }}
  >
    <Button
      style={{
        color: theme.colors.textSecondary,
        fontSize: isDesktop ? "18px" : "16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        {group.group_name}
      </Box>
    </Button>
  </ScrollLink>
);

export default ScrollNavLink;
