import React from "react";
import Carousel from "react-material-ui-carousel";
import theme from "../theme";
import { Box } from "@mui/material";

const Slider = ({ isDesktop }) => {
  const items = [
    {
      image:
        "http://ninasobstundgemuese.soldyn.de/img/image-1704516165585-217901299.jpg",
    },
    {
      image:
        "http://ninasobstundgemuese.soldyn.de/img/image-1704516172418-66126391.jpg",
    },
    {
      image:
        "http://ninasobstundgemuese.soldyn.de/img/image-1704516169247-471091360.jpg",
    },
  ];

  return (
    <Carousel
      indicators={false}
      interval={10000} // Set the delay in milliseconds (e.g., 5000ms for 5 seconds)
      animation="slide" // Set the animation type to "fade"
      duration={1500}
      stopAutoPlayOnHover={true}
      sx={{
        top: isDesktop ? theme.space.large : "0px",
        borderRadius: isDesktop
          ? theme.dimensions.borderRadiusContent
          : `0 0 ${theme.dimensions.borderRadiusContent} ${theme.dimensions.borderRadiusContent}`,
        width: "100%",
        height: "400px",
        overflow: "hidden",
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      {items.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: "100%",
            height: "100%",
            minWidth: isDesktop ? "0px" : "0px",
          }}
        >
          <img
            src={item.image}
            alt={`Slide ${index}`}
            style={{
              position: "relative",
              width: "100%",
              minHeight: isDesktop ? "550px" : "500px",
              objectFit: "cover",
              borderRadius: theme.dimensions.borderRadiusContent,
              marginTop: "-15%",
            }}
          />
        </Box>
      ))}
    </Carousel>
  );
};

export default Slider;
