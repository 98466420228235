// components/Header.js
import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import { fetchSiteTitle, fetchSiteLogo } from "../../api/siteDataApi";
import LoadingIndicator from "../widgets/LoadingIndicator";
import Logo from "../widgets/Logo";
import theme from "../theme";
import { HeaderMobile, HeaderDesktop } from "../widgets/Headers";

const Header = ({ isDesktop }) => {
  const props = {
    isDesktop: isDesktop,
  };

  return (
    <div>
      {isDesktop ? <HeaderDesktop {...props} /> : <HeaderMobile {...props} />}
    </div>
  );
};

export default Header;
