import { createTheme } from "@mui/material";
const theme = createTheme({
  colors: {
    lightGreen: "#118537",
    yellow: "#d8a81c",
    red: "#a11e1f",
    darkGreen: "#295425",
    primary: "#d8a81c", // same as  yellow
    secondary: "#118537", // same as lightGreen
    third: "#a11e1f", // same as red
    accent: "#295425", // same as darkGreen
    textPrimary: "#292c2c",
    textSecondary: "#fff",
    halfTransparent: "rgba(216, 168, 28, 0.5)",
    halfTransparentGrey: "rgba(41, 44, 44, 0.5)",
    halfTransparentSecondary: "rgba(17, 133, 55, 0.5)",
    btnCancel: "#295425",
    btnDelete: "#a11e1f",
    ////
    btnDef: "#4945FF",
    btnSave: "#118537",
    btnDisabled: "#494c52",
  },
  dimensions: {
    contentWidth: "80%",
    borderRadiusContent: "8px",
  },
  space: {
    small: "8px",
    medium: "16px",
    large: "24px",
    contentTop: "75px",
  },
  transitions: {
    short: "295ms",
    middle: "375ms",
    long: "600ms",
    onLoad: "975ms",
  },

  navigation: {
    height: "120px",
    heightShrunk: "100px",
    heightMobile: "100px",
    heightShrunkMobile: "80px",
    heightBurger: "100%",
    width: "100%",
    widthMobile: "100%",
    widthBurger: "45%",
  },
  footer: {
    height: "5%",
    width: "100%",
  },
  typography: {
    fontFamily: "Roboto, serif",
    fontSizeTitles: "24px",
    fontSizeText: "16px",
  },
});

export default theme;
