// ProductList.js
import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../theme";
import { InfoOutlined, InfoRounded } from "@mui/icons-material";
import DescriptionDialog from "./DescriptionDialog";
import AddToCartIcon from "./AddToCartIcon";
const Product = ({
  group,
  products,
  openGroups,
  onGroupClick,
  onAddToCart,
  position,
}) => {
  const [openProductDialogs, setOpenProductDialogs] = useState({});

  const onOpenDescription = (productId) => {
    setOpenProductDialogs((prevState) => ({
      ...prevState,
      [productId]: true,
    }));
  };

  const onCloseDescription = (productId) => {
    setOpenProductDialogs((prevState) => ({
      ...prevState,
      [productId]: false,
    }));
  };

  return position === group.position ? (
    <Accordion
      id={`group-${group.id}`}
      key={group.id}
      expanded={openGroups[group.id]}
      onChange={() => onGroupClick(group.id)}
      style={{ marginBottom: theme.space.small }}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{
              color: theme.colors.accent,
              width: "35px",
              height: "35px",
            }}
          />
        }
        style={{
          cursor: "pointer",

          backgroundColor: theme.colors.secondary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            border: "1px solid",
            borderColor: theme.colors.secondary,
            borderRadius: theme.dimensions.borderRadiusContent,
            width: "120px",
            height: "120px",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: theme.colors.halfTransparentGrey,
          }}
        >
          <img
            style={{
              position: "relative",
              width: "95%",
              height: "95%",
              objectFit: "cover",
              borderRadius: theme.dimensions.borderRadiusContent,
            }}
            src={group.product_group_img}
            alt={group.name}
          />
        </Box>
        <Typography
          style={{
            marginLeft: theme.space.small,
            marginTop: "40px",
            color: theme.colors.textSecondary,
            fontWeight: "bold",
            fontSize: theme.typography.fontSizeTitles,
          }}
        >
          {group.group_name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{ backgroundColor: theme.colors.halfTransparentSecondary }}
      >
        <List>
          {products &&
            products.map((product) =>
              product.is_active ? (
                <ListItem key={product.id}>
                  <Box
                    sx={{
                      display: "flex",
                      border: "1px solid",
                      borderColor: theme.colors.secondary,
                      borderRadius: theme.dimensions.borderRadiusContent,
                      width: "80px",
                      height: "80px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "10px",
                      backgroundColor: theme.colors.halfTransparentGrey,
                    }}
                  >
                    <img
                      style={{
                        position: "relative",
                        width: "95%",
                        height: "95%",
                        objectFit: "cover",
                        borderRadius: theme.dimensions.borderRadiusContent,
                      }}
                      src={
                        product.product_img
                          ? product.product_img
                          : "http://ninasobstundgemuese.soldyn.de/img/image-1704244073076-870237500.png"
                      }
                      alt={group.name}
                    />
                  </Box>
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        style={{
                          fontWeight: "bold",
                          color: theme.colors.textPrimary,
                        }}
                      >
                        {product.product_name}
                      </Typography>
                    }
                    secondary={
                      <div>
                        {product.product_brand ? (
                          <Typography
                            variant="body2"
                            style={{
                              color: theme.colors.textPrimary,
                            }}
                          >
                            <b>Marke: </b>
                            {product.product_brand}
                          </Typography>
                        ) : null}
                        {product.product_origin ? (
                          <Typography
                            variant="body2"
                            style={{
                              color: theme.colors.textPrimary,
                            }}
                          >
                            <b>Herkunft: </b>
                            {product.product_origin}
                          </Typography>
                        ) : null}
                        {product.unit_id ? (
                          <Typography
                            variant="body2"
                            style={{
                              color: theme.colors.textPrimary,
                            }}
                          >
                            <b>Einheit: </b>
                            {` ${
                              product.unit_id === 1
                                ? "kg"
                                : product.unit_id === 2
                                ? "stk"
                                : "Unknown Unit"
                            }`}
                          </Typography>
                        ) : null}
                      </div>
                    }
                  />

                  {product.product_description && (
                    <Tooltip
                      title={
                        <Typography variant="subtitle2">
                          <b>Besonderes...</b>
                        </Typography>
                      }
                      componentsProps={{
                        tooltip: {
                          sx: {
                            bgcolor: theme.colors.accent,
                            color: theme.colors.textSecondary,
                          },
                        },
                      }}
                    >
                      <IconButton
                        color="secondary"
                        onClick={() => onOpenDescription(product.id)}
                      >
                        <InfoOutlined
                          style={{
                            color: theme.colors.textSecondary,
                            width: "35px",
                            height: "35px",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}

                  <AddToCartIcon
                    onClick={() =>
                      onAddToCart(group.id, product.id, product.product_name)
                    }
                  />
                  <DescriptionDialog
                    open={openProductDialogs[product.id] || false}
                    onClose={() => onCloseDescription(product.id)}
                    description={product.product_description}
                  />
                </ListItem>
              ) : null
            )}
        </List>
      </AccordionDetails>
    </Accordion>
  ) : null;
};
export default Product;
