// CartPopup.js
import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
  TextField,
  Typography,
  Tooltip,
  IconButton,
} from "@mui/material";
import theme from "../../theme";
import { DeleteRounded, Remove } from "@mui/icons-material";

const CartPopup = ({
  cart,
  onClose,
  onPlaceOrder,
  onRemoveItem,
  firstName,
  lastName,
  setFirstName,
  setLastName,
  setOrderNote,
  setEmail,
}) => {
  const [emailError, setEmailError] = useState("");
  const [currentEmail, setCurrentEmail] = useState("");
  const [note, setNote] = useState("");
  const maxNoteLength = 255;
  const handleFirstNameChange = (event) => {
    setFirstName(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastName(event.target.value);
  };
  const handleNoteChange = (event) => {
    const newNote = event.target.value;

    setOrderNote(event.target.value);

    if (newNote.length <= maxNoteLength) {
      setNote(newNote);
    }
  };
  const handleEmailChange = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    setCurrentEmail(enteredEmail);
    // Basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValidEmail = emailRegex.test(enteredEmail);

    if (!isValidEmail) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle
        sx={{
          backgroundColor: theme.colors.secondary,
        }}
      >
        <Typography style={{ color: theme.colors.textSecondary }} variant="h6">
          Warenkorb
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        <TextField
          label="Name"
          variant="filled"
          onChange={handleFirstNameChange}
          fullWidth
          margin="normal"
          style={{
            backgroundColor: theme.colors.halfTransparentSecondary,
          }}
          InputProps={{
            style: {
              color: theme.colors.textPrimary,
            },
            focused: {
              color: theme.colors.textPrimary,
              borderColor: theme.colors.textPrimary,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.colors.textSecondary,
            },
            focused: {
              color: theme.colors.textPrimary,
            },
          }}
        />
        <TextField
          label="Nachname"
          variant="filled"
          onChange={handleLastNameChange}
          fullWidth
          margin="normal"
          style={{
            backgroundColor: theme.colors.halfTransparentSecondary,
          }}
          InputProps={{
            style: {
              color: theme.colors.textPrimary,
            },
            focused: {
              color: theme.colors.textPrimary,
              borderColor: theme.colors.textPrimary,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.colors.textSecondary,
            },
            focused: {
              color: theme.colors.textPrimary,
            },
          }}
        />
        <TextField
          label="Email Adresse"
          variant="filled"
          onChange={handleEmailChange}
          fullWidth
          margin="normal"
          style={{
            backgroundColor: theme.colors.halfTransparentSecondary,
          }}
          InputProps={{
            style: {
              color: theme.colors.textPrimary,
            },
            focused: {
              color: theme.colors.textPrimary,
              borderColor: theme.colors.textPrimary,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.colors.textSecondary,
            },
            focused: {
              color: theme.colors.textPrimary,
            },
          }}
        />

        <List>
          {cart.map((item, index) => (
            <ListItem
              key={index}
              style={{
                borderTop: `1px solid ${theme.colors.accent}`,
                borderBottom: `1px solid ${theme.colors.accent}`,
                marginBottom: "8px",
                padding: "12px",
                borderRadius: "8px",
              }}
            >
              <ListItemText
                primary={<strong>{item.productName}</strong>}
                secondary={
                  <Typography variant="subtitle2">
                    Menge: <b>{item.quantity}</b>
                  </Typography>
                }
              />
              <Tooltip
                title={
                  <Typography variant="subtitle2">
                    <b>{item.productName}</b> 1 mal entfernen
                  </Typography>
                }
                componentsProps={{
                  tooltip: {
                    sx: {
                      bgcolor: theme.colors.accent,
                      color: theme.colors.textSecondary,
                    },
                  },
                }}
              >
                <IconButton
                  style={{ color: theme.colors.red }}
                  onClick={() => onRemoveItem(index)}
                >
                  <Remove />
                </IconButton>
              </Tooltip>
            </ListItem>
          ))}
        </List>
        <TextField
          label="Nachricht an uns"
          variant="filled"
          onChange={handleNoteChange}
          fullWidth
          margin="normal"
          multiline
          value={note}
          inputProps={{ maxLength: maxNoteLength }}
          style={{
            height: "auto",
            backgroundColor: theme.colors.halfTransparentSecondary,
          }}
          InputProps={{
            style: {
              color: theme.colors.textPrimary,
            },
            focused: {
              color: theme.colors.textPrimary,
              borderColor: theme.colors.textPrimary,
            },
          }}
          InputLabelProps={{
            style: {
              color: theme.colors.textSecondary,
            },
            focused: {
              color: theme.colors.textPrimary,
            },
          }}
        />
        <Typography
          variant="body2"
          align="right"
          style={{
            color:
              note.length === maxNoteLength
                ? theme.colors.textPrimary
                : "inherit",
          }}
        >
          {note.length}/{maxNoteLength}
        </Typography>
        <Typography variant="subtitle2">
          Sie erhalten eine Email mit dem Gesamtpreis, bitte holen Sie Ihre
          Bestellung erst ab, wenn Sie diese Bestellbestätigung erhalten haben.
        </Typography>
        <Tooltip
          title={
            cart.length === 0 ||
            !firstName ||
            !lastName ||
            !currentEmail ||
            !!emailError ? (
              <Typography variant="subtitle2">
                Bitte füllen Sie alle Felder aus
              </Typography>
            ) : null
          }
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: theme.colors.accent,
                color: theme.colors.textSecondary,
              },
            },
          }}
          disabled={
            cart.length === 0 ||
            !firstName ||
            !lastName ||
            !currentEmail ||
            !!emailError
          }
        >
          {" "}
          <Button
            onClick={onPlaceOrder}
            variant="contained"
            style={{
              backgroundColor:
                cart.length === 0 ||
                !firstName ||
                !lastName ||
                !currentEmail ||
                !!emailError
                  ? ""
                  : theme.colors.btnSave,
            }}
            disabled={
              cart.length === 0 ||
              !firstName ||
              !lastName ||
              !currentEmail ||
              !!emailError
            }
          >
            Bestellen
          </Button>
        </Tooltip>

        <Button
          style={{
            color: theme.colors.textSecondary,
            backgroundColor: theme.colors.btnDelete,
            marginLeft: theme.space.small,
          }}
          onClick={onClose}
        >
          Abbrechen
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default CartPopup;
