import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import theme from "../../theme";

const DescriptionDialog = ({ open, onClose, description }) => {
  const [editorContent, setEditorContent] = useState(description);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent
        sx={{
          backgroundColor: theme.colors.halfTransparentSecondary,
        }}
      >
        <ReactQuill value={editorContent} readOnly={true} theme="bubble" />
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: theme.colors.secondary,
        }}
      >
        <Button
          onClick={onClose}
          style={{
            backgroundColor: theme.colors.btnCancel,
            color: theme.colors.textSecondary,
          }}
        >
          Zurück
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DescriptionDialog;
