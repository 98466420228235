// App.js
import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./components/theme";
import AppShell from "./components/layout/AppShell";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <AppShell />
    </ThemeProvider>
  );
};

export default App;
