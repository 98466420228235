import React, { useState } from "react";
import {
  Container,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { ImprintContent, PrivacyPolicyContent } from "../widgets/Dialogs";

const Footer = ({ isDesktop }) => {
  const [openImprintModal, setOpenImprintModal] = useState(false);
  const [openPrivacyModal, setOpenPrivacyModal] = useState(false);

  const handleImprintOpen = () => {
    setOpenImprintModal(true);
  };

  const handleImprintClose = () => {
    setOpenImprintModal(false);
  };

  const handlePrivacyOpen = () => {
    setOpenPrivacyModal(true);
  };

  const handlePrivacyClose = () => {
    setOpenPrivacyModal(false);
  };

  return (
    <Container>
      <Typography variant="body2" align="center">
        © {new Date().getFullYear()}{" "}
        <a
          href="https://solutiondynamic.de/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Solution Dynamic
        </a>
        .{" | "}
        <Button onClick={handleImprintOpen}>Impressum</Button>
        {" | "}
        <Button onClick={handlePrivacyOpen}>Datenshutzerklärung</Button>
      </Typography>

      {/* Imprint Dialog */}
      <ImprintContent open={openImprintModal} onClose={handleImprintClose} />

      <PrivacyPolicyContent
        open={openPrivacyModal}
        onClose={handlePrivacyClose}
      />
    </Container>
  );
};

export default Footer;
