import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { fetchSiteLogo } from "../../api/siteDataApi";
import theme from "../theme";
const Logo = ({ position, top, left }) => {
  const [siteLogo, setSiteLogo] = useState(null);

  useEffect(() => {
    getSiteLogo();
  }, []);

  const getSiteLogo = async () => {
    try {
      const fetchedSiteLogo = await fetchSiteLogo();
      setSiteLogo(fetchedSiteLogo);
    } catch (error) {
      console.error("Error fetching fetchedSiteLogo data:", error);
    }
  };

  return (
    <Box>
      <a href="/" style={{ textDecoration: "none" }}>
        <Box
          margin={2}
          style={{
            position: position,
            backgroundColor: theme.colors.halfTransparentGrey,
            padding: 5,
            borderRadius: "90px",
            top: top,
            left: left,
            cursor: "pointer",
            opacity: siteLogo ? 1 : 0,
            transition: `opacity ${theme.transitions.onLoad} ease-in-out`,
          }}
        >
          <img
            src={siteLogo}
            alt="Logo"
            style={{ maxHeight: 70, maxWidth: "100%", marginTop: 1.5 }}
          />
        </Box>
      </a>
    </Box>
  );
};

export default Logo;
