// api.js
import { API_URL } from "../const";

export const fetchData = async (endpoint) => {
  try {
    console.log("Trying to fetch data from:", `${API_URL}${endpoint}`);

    const response = await fetch(`${API_URL}${endpoint}`);

    console.log("Response Status:", response.status);

    if (!response.ok) {
      throw new Error(`Failed to fetch data. Status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    console.log("Response Content Type:", contentType);

    if (!contentType || !contentType.includes("application/json")) {
      // Handle unexpected response content type
      throw new Error("Invalid response content type. Expected JSON.");
    }

    const data = await response.json();
    console.log("Fetched Data:", data);
    return data;
  } catch (error) {
    console.error(`Error fetching data: ${error.message}`);
    throw error; // Re-throw the error to be handled by the calling function
  }
};
