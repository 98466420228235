import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import theme from "../../theme";
import Logo from "../Logo";
import LoadingIndicator from "../LoadingIndicator";
const Loading = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100%"
    >
      <Box
        boxShadow={3}
        borderRadius={8}
        p={4}
        bgcolor={theme.colors.secondary}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Logo position={"relative"} alt={"404"} />
        <LoadingIndicator />
      </Box>
    </Box>
  );
};

export default Loading;
