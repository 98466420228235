import styled from "@emotion/styled";
import { Box, Container } from "@mui/material";
import theme from "./components/theme";

////////////////////////////////DESKTOP////////////////////////////////
export const StyledHeaderMenu = styled.div({
  width: theme.navigation.width,
  height: theme.navigation.height,
  backgroundColor: theme.colors.secondary,
  display: "flex",
  overflow: "hidden",
  position: "fixed",
  justifyContent: "center", // Center horizontally
  alignItems: "center", // Center vertically
  zIndex: 99,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});
////////////////////////////////MOBILE/////////////////////////////////
export const StyledMobileMenu = styled.div({
  width: theme.navigation.widthMobile,
  height: theme.navigation.heightMobile,
  backgroundColor: theme.colors.secondary,
  display: "flex",
  overflow: "hidden",
  position: "fixed",
  zIndex: 99,
  justifyContent: "space-between",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});

export const StyledBurgerMenu = styled.div((props) => ({
  left: props.isOpen ? 0 : `-${theme.navigation.widthBurger}`,
  top: theme.navigation.heightMobile,
  width: theme.navigation.widthBurger,
  height: theme.navigation.heightBurger,
  position: "fixed",
  backgroundColor: theme.colors.secondary,
  transition: `left ${theme.transitions.middle} ease-in-out`,
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
}));
