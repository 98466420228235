import React, { useState, useEffect } from "react";
import { IconButton, MenuList, Toolbar, useScrollTrigger } from "@mui/material";
import Logo from "./Logo";
import theme from "../theme";
import { fetchSiteTitle } from "../../api/siteDataApi";
import {
  StyledBurgerMenu,
  StyledHeaderMenu,
  StyledMobileMenu,
} from "../../StyledComponents";
import Menu from "./Menu";
import {
  MenuBook,
  MenuOpenSharp,
  MenuRounded,
  MenuSharp,
} from "@mui/icons-material";

const useDataFetchingAndTitle = () => {
  const [data, setData] = useState({ siteTitle: "", siteLogo: "" });

  useEffect(() => {
    const fetchDataAndSetTitle = async () => {
      try {
        const fetchedSiteTitle = await fetchSiteTitle();

        setData({
          siteTitle: fetchedSiteTitle,
        });
      } catch (error) {
        console.error("Error fetching fetchedSiteTitle data:", error);
      } finally {
      }
    };

    fetchDataAndSetTitle();
  }, []);

  return data;
};
const ElevationScroll = ({ children }) => {
  const trigger = useScrollTrigger();

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    isShrunk: trigger, // Pass the scroll trigger value
  });
};
export const HeaderDesktop = ({ isDesktop }) => {
  const { siteTitle } = useDataFetchingAndTitle();
  const onRouteClick = (route) => {
    console.log(`${route} clicked!`);
  };
  return (
    <ElevationScroll>
      <StyledHeaderMenu isShrunk={false}>
        <Logo position="absolute" top="0" left="0" alt={siteTitle} />
        <Menu onRouteClick={onRouteClick} isDesktop={isDesktop} />
        <div />
      </StyledHeaderMenu>
    </ElevationScroll>
  );
};
export const HeaderMobile = ({ isDesktop }) => {
  const { siteTitle } = useDataFetchingAndTitle();
  const [fadeIn, setFadeIn] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onBurgerMenuClick = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(true);
    }
  };
  const onRouteClick = () => {
    setIsMenuOpen(false);
  };
  useEffect(() => {
    setFadeIn(true);
  });
  return (
    <ElevationScroll>
      <StyledMobileMenu isShrunk={false}>
        <Logo position="absolute" top="0" left="0" alt={siteTitle} />

        <IconButton
          style={{
            opacity: fadeIn ? 1 : 0,
            transition: `opacity ${theme.transitions.onLoad} ease-in-out`,
          }}
          color="inherit"
          aria-label="menu"
          onClick={onBurgerMenuClick}
        >
          {!isMenuOpen ? (
            <MenuSharp
              sx={{
                width: "35px",
                height: "35px",
                color: theme.colors.accent,
              }}
            />
          ) : (
            <MenuOpenSharp
              sx={{
                width: "35px",
                height: "35px",
                color: theme.colors.accent,
              }}
            />
          )}
        </IconButton>

        <StyledBurgerMenu isOpen={isMenuOpen}>
          <Menu onRouteClick={onRouteClick} isDesktop={isDesktop} />
        </StyledBurgerMenu>
      </StyledMobileMenu>
    </ElevationScroll>
  );
};
